import { lighten } from 'polished';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { colors } from './colors';

export const outer = css`
  position: relative;
  padding: 0 5vw;
`;

export const outerFull = css`
  position: relative;
  padding: 3rem 5vw;
`;

export const outerBordered = css`
  background-color: #fafafa;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
`;

// Centered content container blocks
export const inner = css`
  margin-right: auto;
  margin-left: auto;
  max-width: 1040px;
  width: 100%;
`;

export const innerNarrow = css`
  margin: 0 auto;
  max-width: 80%;

  @media (min-width: 1100px) {
    max-width: 45%;
  }
`;

export const SiteNavMain = css`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  /* background: color(var(--darkgrey) l(-5%)); */
  background: ${lighten('-0.05', colors.darkgrey)};
`;

export const SiteMain = css`
  flex-grow: 1;

  @media (prefers-color-scheme: dark) {
    background: ${colors.darkmode};
  }
`;

export const SiteTitle = styled.h1`
  z-index: 10;
  margin: 0 0 0 -2px;
  padding: 0;
  font-size: 5rem;
  line-height: 1em;
  font-weight: 600;
  text-align: center;

  & + h3 {
    text-align: center;
  }

  svg {
    height: 9rem;
    max-width: 80%;
  }

  @media (max-width: 500px) {
    font-size: 4.2rem;
  }
`;

export const SiteDescription = styled.h2`
  z-index: 10;
  margin: 0;
  padding: 5px 0;
  font-size: 2.1rem;
  line-height: 1.4em;
  font-weight: 400;
  opacity: 0.8;

  @media (max-width: 500px) {
    font-size: 1.8rem;
  }
`;

export const Posts = css`
  overflow-x: hidden;
`;

export const PostFeed = css`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  padding: 50px 0 0;
  background: #fff;

  /* Special Template Styles */
  padding: 40px 0 5vw;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  @media (prefers-color-scheme: dark) {
    background: ${colors.darkmode};
  }
`;

export const SocialLink = css`
  display: inline-block;
  margin: 0;
  padding: 10px;
  opacity: 0.8;

  :hover {
    opacity: 1;
  }

  svg {
    height: 1.8rem;
    fill: #000;
  }
`;

export const SiteHeader = css`
  width: 100%;
`;

export const SiteHeaderHero = styled.div`
  margin: 0 auto;
  text-align: center;
  width: 90%;

  img {
    display: inline-block;
    margin: 0 auto;
    max-width: 90%;

    @media (min-width: 600px) {
      max-width: 60%;
    }
  }
`;

export const SiteHeaderContent = styled.div`
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3vw;
  max-height: 340px;
`;

export const SiteHeaderStyles = css`
  position: relative;
  /* margin-top: 64px; */
  padding-bottom: 12px;
  color: #000;
  /* background: color(var(--darkgrey) l(-5%)) no-repeat center center; */
  /*
  background: ${lighten('-0.05', colors.darkgrey)} no-repeat center center;
  background-size: cover;

  :before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: block;
    background: rgba(0, 0, 0, 0.18);
  }
  :after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
    z-index: 10;
    display: block;
    height: 140px;
    background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
  }

  @media (prefers-color-scheme: dark) {
    :before {
      background: rgba(0, 0, 0, 0.6);
    }
  }
  */
`;

export const AuthorProfileImage = css`
  flex: 0 0 60px;
  margin: 0;
  width: 60px;
  height: 60px;
  border: none;

  @media (prefers-color-scheme: dark) {
    box-shadow: 0 0 0 6px hsla(0, 0%, 100%, 0.04);
    background: ${colors.darkmode};
  }
`;

// tag and author post lists
export const SiteArchiveHeader = css`
  .site-header-content {
    position: relative;
    align-items: stretch;
    padding: 12vw 0 20px;
    min-height: 200px;
    max-height: 600px;
  }
`;

export const SiteHeaderBackground = css`
  margin-top: 64px;
`;

export const ResponsiveHeaderBackground = styled.div<{ backgroundImage?: string }>`
  ${p =>
    p.backgroundImage &&
    `
    position: relative;
    margin-top: 64px;
    padding-bottom: 12px;
    color: #000;
    background-size: cover;
    /* background: color(var(--darkgrey) l(-5%)) no-repeat center center; */
    background: #090a0b no-repeat 50%;
    background-image: url(${p.backgroundImage});

    :before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
      display: block;
      background: rgba(0, 0, 0, 0.18);
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: auto;
      left: 0;
      z-index: 10;
      display: block;
      height: 140px;
      background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
    }

    @media (prefers-color-scheme: dark) {
      &:before {
        background: rgba(0, 0, 0, 0.6);
      }
    }
  `}

  ${p =>
    !p.backgroundImage &&
    `

    padding-top: 0;
    padding-bottom: 0;
    /* color: var(--darkgrey); */
    color: ${colors.darkgrey};
    background: #fff;
    opacity: 1;


  .site-description {
    /* color: var(--midgrey); */
    color: ${colors.midgrey};
    opacity: 1;
  }

  .site-header-content {
    padding: 5vw 0 10px;
    /* border-bottom: 1px solid color(var(--lightgrey) l(+12%)); */
    border-bottom: 1px solid ${lighten('0.12', colors.lightgrey)};
  }

  @media (max-width: 500px) {
    .site-header-content {
      flex-direction: column;
      align-items: center;
      min-height: unset;
    }

    .site-title {
      font-size: 4.2rem;
      text-align: center;
    }

    .site-header-content {
      padding: 12vw 0 20px;
    }
  }
  @media (prefers-color-scheme: dark) {
    color: rgba(255, 255, 255, 0.9);
    /* background: var(--darkmode); */
    background: ${colors.darkmode};

    .site-header-content {
      /* border-bottom-color: color(var(--darkmode) l(+15%)); */
      /* border-bottom-color: ${lighten('0.15', colors.darkmode)}; */
      border-bottom-color: #272a30;
    }
  }
  `}
`;

export const NoImage = css`
  .no-image {
    padding-top: 0;
    padding-bottom: 0;
    /* color: var(--darkgrey); */
    color: ${colors.darkgrey};
    background: #fff;
    opacity: 1;
  }

  .no-image .site-description {
    /* color: var(--midgrey); */
    color: ${colors.midgrey};
    opacity: 1;
  }

  .no-image .site-header-content {
    padding: 5vw 0 10px;
    /* border-bottom: 1px solid color(var(--lightgrey) l(+12%)); */
    border-bottom: 1px solid ${lighten('0.12', colors.lightgrey)};
  }

  @media (max-width: 500px) {
    .site-header-content {
      flex-direction: column;
      align-items: center;
      min-height: unset;
    }

    .site-title {
      font-size: 4.2rem;
      text-align: center;
    }

    .no-image .site-header-content {
      padding: 12vw 0 20px;
    }
  }
  @media (prefers-color-scheme: dark) {
    .no-image {
      color: rgba(255, 255, 255, 0.9);
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }

    .no-image .site-header-content {
      /* border-bottom-color: color(var(--darkmode) l(+15%)); */
      border-bottom-color: ${lighten('0.15', colors.darkmode)};
    }
  }
`;

export const Section = styled.section`
  margin: 4em auto;

  & + & {
    margin-top: 0;
  }
`;

export const SectionIntro = styled.div`
  text-align: center;

  p:only-child {
    margin-bottom: 0;
  }

  h2:only-child,
  h3:only-child {
    margin: 0;
  }

  h2 {
    font-weight: 500;
    line-height: 3.5rem;
  }

  h3 {
    font-size: 3rem;
    line-height: 3.5rem;
    font-weight: lighter;
    text-transform: uppercase;
    letter-spacing: 0.25rem;
  }
`;

export const SectionContent = styled.div`
  position: relative;
  flex: 1 1 301px;
  display: flex;
  flex-direction: column;
  background-size: cover;

  & > form {
    margin: 0 auto;
    width: 100%;

    @media (min-width: 795px) {
      max-width: 80%;
    }
  }

  @media (min-width: 795px) {
    flex: 1 1 100%;
    flex-direction: row;
    border-top: 0;

    section:nth-of-type(even) & {
      flex-direction: row-reverse;
    }
  }
`;

export const SectionContentColumn = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 1em;
  margin: 2em auto;
  width: 100%;

  :first-of-type {
    img {
      margin-left: 0;
    }
  }

  :last-of-type {
    margin-bottom: 0;

    img {
      margin-left: 0;
    }
  }

  @media (min-width: 795px) {
    flex-direction: column;
    flex-basis: content;
    flex: 1;
    justify-content: center;
    margin: 0 auto;
    padding: 0 5rem;

    :first-of-type {
      margin-left: 0;
    }

    :last-of-type {
      margin-right: 0;
    }
  }

  a, img {
    margin: 0 auto;
    width: 100%;
  }

  .gatsby-image-wrapper {
    width: 100%;
  }
`;

export const SectionContentTitle = styled.h2`
  letter-spacing: 0.25rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
`;

export const SectionContentContent = styled.div`
  width: 100%;
`;
